
function ExibeMensagem(props) {

    return <div className="row">
        <div className="d-flex justify-content-center mt-3">
            <div className="text-center alert alert-success w-20 col-6" role="alert">
                {props.msn}
            </div>
        </div>
    </div>
}
export default ExibeMensagem;