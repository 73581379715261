import axios from "axios";
import { Dock } from "react-dock";
import { useEffect, useState } from "react";
import "./franquias-cad.css";
import api from "../../services/api.js";
import generatePDF, { Margin } from 'react-to-pdf';
import { dadosLogin } from "../../zustand/zuztand.js";

import btnClear from "../../assets/clear.png";
import btnAtualiza from "../../assets/atualizar.png"
import ExibeMensagem from "../../components/mensagens/mensagens.js";
import franquiasCidadesPDF from "../../report/report.franquias-cidades/report.franquias-cidades.jsx";

function FranquiasCad(props) {
    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [id_franquia, setId_franquia] = useState(0);
    const [nome_franquia, setNome_franquia] = useState("");
    const [razao_franquia, setRazao_franquia] = useState("");
    const [tel_franquia, setTel_franquia] = useState("");
    const [data_inalg, setData_inalg] = useState("");
    const [cnpj_franquia, setCnpj_franquia] = useState("");
    const [comp_franquia, setComp_franquia] = useState("");
    const [num_franquia, setNum_franquia] = useState("");
    const [obs_franquia, setObs_franquia] = useState("");
    const [data_encerramento, setData_encerramento] = useState("");
    const [motivo_franquia, setMotivo_franquia] = useState("");
    const [nome_franqueados, setNome_franqueados] = useState("");
    const [tipo_franquia, setTipo_franquia] = useState("Selecione");
    const [ie_franquia, setIe_franquia] = useState("");
    const [im_franquia, setIm_franquia] = useState("");
    const [data_contrato, setData_contrato] = useState("");
    const [cidades_abrang, setCidades_abrang] = useState("");
    const [data_aniver_cidade, setData_aniver_cidade] = useState("");
    const [end_franquia, setEnd_franquia] = useState("");
    const [cidade_franquia, setCidade_franquia] = useState("");
    const [bairro_franquia, setBairro_franquia] = useState("");
    const [uf_franquia, setUf_franquia] = useState("");
    const [cep_franquia, setCep_franquia] = useState("");

    const [end_franquiaModal, setEnd_franquiaModal] = useState("");
    const [cidade_franquiaModal, setCidade_franquiaModal] = useState("");
    const [bairro_franquiaModal, setBairro_franquiaModal] = useState("");
    const [uf_franquiaModal, setUf_franquiaModal] = useState("");
    const [cep_digitado, setCep_digitado] = useState("");
    const [cep_digitado_temp, setCep_digitado_temp] = useState("");

    const [cidadesEscolhidas, setCidadesEscolhidas] = useState([]);
    const [nome_cidade, setNome_cidade] = useState("");
    const [nome_cidade_string, setNome_cidade_string] = useState("");
    const [id, setId] = useState(0);// para armazenar o id da cidade do ibge no bd
    const [textoDigitado, setTextoDigitado] = useState(""); //para consulta autocomplete
    const [cidadeExcluir, setCidadeExcluir] = useState('');
    const [cidadeIncluir, setCidadeIncluir] = useState('');
    const [idCidadeExcluir, setIdCidadeExcluir] = useState(0);
    const [sucessoExclusao, setSucessoExclusao] = useState(false);
    const [sucessoInclusao, setSucessoInclusao] = useState(false);
    const [btnAbreModal, setBtnAbreModal] = useState(true);
    const [msnCidadeIncluida, setMsnCidadeIncluida] = useState(false); //para controle de mensagem cidade ja incluida no banco de dados

    const [dadosCidades, setDadosCidades] = useState([]); //cidades que correspondem ao texto pesquisa autocomplete   
    const [cidades, setCidades] = useState([]); //todas as cidades pela pesquisa api uf
    const [cidadesFranquiasUf, setCidadesFranquiasUf] = useState([]); //todas as cidades pela pesquisa no bd pela uf cidade
    const [cidades_aptas, setCidades_aptas] = useState([]);
    const [uf_pesquisa, setUf_pesquisa] = useState(""); //para pesquisa todas as cidades no abrir a tela


    const { usuario, nivel_user } = dadosLogin();

    const recuperarConteudoParaPDF = () => document.getElementById('conteudo-pdf');
    const personalizacao = { //para impressão pdf
        // Baixar/Salvar = save / Abrir no navegador = open
        method: 'open',
        page: {
            // Definir a margem: SMALL ou MEDIUM 
            margin: Margin.SMALL,
            // Formato da página: A4 ou letter
            format: 'A4',
            // Orientação do arquivo: portrait ou landscape
            orientation: 'portrait',
        },
    }

    //---------------------------------------carregados oa iniciar inicio -----------------------------------------
    function consultaCidadespelaUfGeral() { //consulta todas as cidades pela uf (API IBGE)

        setCidades([]);
        let arrayCid = [];
        let arraycidadesuf = [];
        var arraynovo = [];
        var arraynovo2 = [];

        axios
            .get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf_pesquisa}/municipios`
            )
            .then((response) => {
                //console.log(response.data[0].microrregiao.mesorregiao.UF.sigla)         

                response.data.map((item) => {
                    arrayCid.push({
                        'id': item.id,
                        'nome_cidade': item.nome,
                        'uf': response.data[0].microrregiao.mesorregiao.UF.sigla
                    })
                });
                setCidades(arrayCid);
                //console.log('uf pesquisada' +uf_pesquisa)
                //console.log('listando cidades IBGE')
                // console.log(arrayCid.length)          
            });
        //-----------------------------------------------------------------------------
        let uf_cidade = uf_pesquisa;

        api.get("/cidades_franquia_uf", { params: { uf_cidade } })
            .then((resp) => {
                //console.log(resp.data);               
                resp.data.map((item) => {
                    // console.log(item.uf_cidade)                    
                    arraycidadesuf.push(
                        {
                            'id': item.id,
                            'nome_cidade': item.nome_cidade,
                            'uf': item.uf_cidade
                        })
                })
                setCidadesFranquiasUf(arraycidadesuf);
                // console.log('listando cidades Banco de dados')
                // console.log(arraycidadesuf.length);                                 
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
                console.log(err);
            });

        //---------------------------------------------------------------     
        cidades.map((dad) => { //montando primeiro array
            arraynovo.push(
                {
                    'id': dad.id,
                    'nome_cidade': dad.nome_cidade,
                    'uf': dad.uf
                }
            )
        })
        //console.log(cidades)
        cidadesFranquiasUf.map((cid) => {
            arraynovo2.push({  //montando segundo array
                'id': cid.id,
                'nome_cidade': cid.nome_cidade,
                'uf': cid.uf
            })
        })

        const getUniqueData = (arraynovo, arraynovo2) => {
            return [
                ...arraynovo.filter(item1 => !arraynovo2.some(item2 => item1.id === item2.id)),
                ...arraynovo2.filter(item2 => !arraynovo.some(item1 => item2.id === item1.id))
            ];
        };
        setCidadesEscolhidas([]);
        //console.log('cidades esc 1')
        //console.log(cidadesEscolhidas.length)
        // Gerando o novo array com dados únicos
        const uniqueArray = getUniqueData(arraynovo, arraynovo2);
        setCidadesEscolhidas(uniqueArray);


        //console.log('cidades esc 2')
        // console.log(cidadesEscolhidas.length)        

        //alert("setando cidades escolhidas")    

        // console.log(uniqueArray);

    }


    function consultacidadespelaUf() { //consulta todas as cidades pela uf (API IBGE)
        setCidadeIncluir("");

        setCidades([]);
        axios
            .get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf_pesquisa}/municipios`
            )
            .then((response) => {
                //console.log(response.data[0].microrregiao.mesorregiao.UF.sigla)         
                let arrayCid = [];
                response.data.map((item) => {
                    arrayCid.push({
                        'id': item.id,
                        'nome_cidade': item.nome,
                        'uf': response.data[0].microrregiao.mesorregiao.UF.sigla
                    })
                });
                setCidades(arrayCid);
                //  console.log('listando cidades IBGE')
                //console.log(cidades)
            });
    }


    function consultaCidadesFranquiasPelaUf() { //consulta todas as cidades utilizadas pelas franquias no bd
        let uf_cidade = uf_pesquisa;

        api.get("/cidades_franquia_uf", { params: { uf_cidade } })
            .then((resp) => {
                //console.log(resp.data);
                let arraycidadesuf = []
                resp.data.map((item) => {
                    // console.log(item.uf_cidade)                    
                    arraycidadesuf.push(
                        {
                            'id': item.id,
                            'nome_cidade': item.nome_cidade,
                            'uf': item.uf_cidade
                        })
                })
                setCidadesFranquiasUf(arraycidadesuf);
                // console.log('listando cidades Banco de dados')
                // console.log(arraycidadesuf);                       
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
                console.log(err);
            });
    }

    function pesquisarCidadesDuplicadas() { //para não aparecer as cidades já cadastradas no select autocomplete
        //  consultacidadespelaUf();
        var arraynovo = []
        var arraynovo2 = []
        cidades.map((dad) => { //montando primeiro array
            arraynovo.push(
                {
                    'id': dad.id,
                    'nome_cidade': dad.nome_cidade,
                    'uf': dad.uf
                }
            )
        })
        //console.log(cidades)
        cidadesFranquiasUf.map((cid) => {
            arraynovo2.push({  //montando segundo array
                'id': cid.id,
                'nome_cidade': cid.nome_cidade,
                'uf': cid.uf
            })
        })

        for (var i = 0; i < arraynovo.length; i++) {

            for (var j = 0; j < arraynovo2.length; j++) {
                if (arraynovo2[j].id === arraynovo[i].id) { //comparando se id igual
                    arraynovo.splice(i, 1)
                }
            }
        }
        setCidades_aptas(arraynovo.sort()) //somente as cidades que estão disponíveis
        // setCidades_aptas(arraynovo.sort())
        // console.log("escolhendo cidades aptas")
        //console.log(arraynovo)
    }


    function listarCidadesFranquias() {  //pesquias as cidades cadastradas pelo id da franquia para preencher o select e o text area

        let id_franquia = parseInt(localStorage.getItem('id_franquia'));

        api.get("/cidades_list", { params: { id_franquia } })
            .then((resp) => {
                //console.log(resp.data);
                let array = []
                resp.data.map((item) => {
                    array.push(' ' + item.nome_cidade.toString()); //inclui espaço entre a virgula                    
                })
                //console.log(array)
                setNome_cidade_string(array.toString()); //monta a relação de cidades no text area
                // console.log(nome_cidade_string)
                setCidadesEscolhidas(resp.data); //todas as cidades de abrangencia da franquias escolhida id_franquia
                //console.log(cidadesEscolhidas)              
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
                console.log(err);
            });
    }

    function ListarCidadesBusca(buscaCidades) {

        // console.log(cidades_aptas[0])     

        if (!buscaCidades) return [];
        setDadosCidades(cidades.filter(cidade =>
            cidade.nome_cidade.toLowerCase().startsWith(buscaCidades.toLowerCase())
        ));
        //console.log(dadosCidades)// cidades que correspondem ao txto pesquisa autocomplete
    }



    //---------------------------------------carregados oa iniciar fim -----------------------------------------


    //---------------------------------------Consulta CEP inicio  ----------------------------------------------
    function getCep() {
        const cepFran = cep_digitado.replace(/\D/g, '')
        if (cep_digitado.length !== 8) {
            alert("CEP inválido.");
            setEnd_franquiaModal('');
            setCidade_franquiaModal('');
            setBairro_franquiaModal('');
            setUf_franquiaModal('');

        } else {
            fetch(`https://viacep.com.br/ws/${cep_digitado}/json/`).then(res => res.json()).then(data => {
                //  console.log(data);
                if (data.erro) {
                    alert("CEP não encontrado.")
                    setEnd_franquiaModal('');
                    setCidade_franquiaModal('');
                    setBairro_franquiaModal('');
                    setUf_franquiaModal('');
                } else {
                    setEnd_franquiaModal(data.logradouro);
                    // localStorage.setItem('Rua', data.logradouro);
                    setBairro_franquiaModal(data.bairro);
                    // localStorage.setItem('Bairro', data.bairro);
                    setCidade_franquiaModal(data.localidade);
                    //localStorage.setItem('Local', data.localidade);
                    // setCep(data.cep);
                    setUf_franquiaModal(data.uf);
                }
            });
        }
    }
    function usarNovoCep() {//carrega os dados do modal na tela cad

        if (end_franquiaModal === "") {
            alert('Cep inválido!')
        } else {
            setEnd_franquia(end_franquiaModal);
            setCidade_franquia(cidade_franquiaModal);
            setBairro_franquia(bairro_franquiaModal);
            setUf_franquia(uf_franquiaModal);
            setCep_franquia(cep_digitado);
        }
    }

    function cancelarModal() {
        setCep_franquia(localStorage.getItem('cep_franquia_temp'));
        setEnd_franquia(localStorage.getItem('end_franquia_temp'));
        setCidade_franquia(localStorage.getItem('cidade_franquia_temp'));
        setBairro_franquia(localStorage.getItem('bairro_franquia_temp'));
        setUf_franquia(localStorage.getItem('uf_franquia_temp'));

        setEnd_franquiaModal('');
        setCidade_franquiaModal('');
        setBairro_franquiaModal('');
        setUf_franquiaModal('');
        setCep_digitado('');
    }

    function sair() {
        setEnd_franquiaModal('');
        setCidade_franquiaModal('');
        setBairro_franquiaModal('');
        setUf_franquiaModal('');
        setCep_digitado('');
    }
    //---------------------------------------Consulta CEP fim  ----------------------------------------------

    function Limpar() {
        setNome_franquia("");
        setRazao_franquia("");
        setEnd_franquia("");
        setData_inalg("");
        setCnpj_franquia("");
        setTel_franquia("");
        setCep_franquia("");
        setBairro_franquia("");
        setUf_franquia("");
        setCidade_franquia("");
        setComp_franquia("");
        setNum_franquia("");
        setObs_franquia("");
        setData_encerramento("");
        setMotivo_franquia("");
        setNome_franqueados("");
        setTipo_franquia("");
        setIe_franquia("");
        setIm_franquia("");
        setCidades_abrang("");
        setData_contrato("");
        setData_aniver_cidade("");
        setCidadeIncluir("");
    }

    function ChangeDataInalgFranquia(e) {
        let ano = e.target.value.substring(0, 4);
        let mes = e.target.value.substring(5, 7);
        let dia = e.target.value.substring(8, 11);
        setData_inalg(dia + '/' + mes + '/' + ano);
    }

    function SalvarDadosFranquia() {

        if (uf_franquia === "PR" || uf_franquia === "SC" || uf_franquia === "RS") {
            localStorage.setItem('regiao', 'Sul');

        } else if (uf_franquia === "SP" || uf_franquia === "RJ" || uf_franquia === "MG" || uf_franquia === "ES") {
            localStorage.setItem('regiao', 'Sudeste');

        } else if (uf_franquia === "MS" || uf_franquia === "MT" || uf_franquia === "GO" || uf_franquia === "DF") {
            localStorage.setItem('regiao', 'Centro-Oeste');

        } else if (uf_franquia === "MA" || uf_franquia === "PI" || uf_franquia === "CE" || uf_franquia === "RN" || uf_franquia === "PB" || uf_franquia === "PE" || uf_franquia === "AL" || uf_franquia === "SE" || uf_franquia === "BA") {
            localStorage.setItem('regiao', 'Nordeste');
        } else if (uf_franquia === "RO" || uf_franquia === "AC" || uf_franquia === "AM" || uf_franquia === "RR" || uf_franquia === "AP" || uf_franquia === "PA" || uf_franquia === "TO") {
            localStorage.setItem('regiao', 'Norte');
        }

        if (nome_franquia === "" || cnpj_franquia === "" || uf_franquia === "Selecione" || uf_franquia === "") {
            alert("Campo nome, CNPJ , e UF são obrigatórios")
        } else {
            let status_franquia = "A";
            let id_usuario = 1;
            let regiao2 = localStorage.getItem('regiao');

            const params = {
                nome_franquia,
                razao_franquia,
                regiao: regiao2,
                data_inalg,
                end_franquia,
                cnpj_franquia,
                tel_franquia,
                status_franquia,
                cep_franquia,
                num_franquia,
                cidade_franquia,
                bairro_franquia,
                comp_franquia,
                uf_franquia,
                obs_franquia,
                id_usuario,
                data_encerramento,
                motivo_franquia,
                nome_franqueados,
                tipo_franquia,
                ie_franquia,
                im_franquia,
                cidades_abrang: " ",
                data_contrato,
                data_aniver_cidade
            };

            const params2 = {
                nome_franquia,
                razao_franquia,
                regiao: regiao2,
                data_inalg,
                end_franquia,
                cnpj_franquia,
                tel_franquia,
                cep_franquia,
                num_franquia,
                cidade_franquia,
                bairro_franquia,
                comp_franquia,
                uf_franquia,
                obs_franquia,
                data_encerramento,
                motivo_franquia,
                nome_franqueados,
                tipo_franquia,
                ie_franquia,
                im_franquia,
                cidades_abrang: nome_cidade_string,
                data_contrato,
                data_aniver_cidade
            };

            if (operacao === "new") {
                // NOVO REGISTRO........................
                setCep_franquia(localStorage.getItem('cep_franquia_temp'));
                setEnd_franquia(localStorage.getItem('end_franquia_temp'));
                setCidade_franquia(localStorage.getItem('cidade_franquia_temp'));
                setBairro_franquia(localStorage.getItem('bairro_franquia_temp'));
                setUf_franquia(localStorage.getItem('uf_franquia_temp'));

                api.post("/franquias_insert", params)
                    .then((resp) => {
                        if (resp.status === 201) {
                            alert("Incluído com sucesso")
                            localStorage.setItem('uf_franquia', uf_franquia);
                            setShow(false);
                            props.onClose(); //para atualizar a tabela 
                        } else {
                            alert("Erro ao inserir dados franquia");
                        }
                    })
                    .catch((err) => {
                        //setDados([]);
                        alert("Erro ao inserir dados ");
                    });
            } else {
                if (nome_franquia === "" || razao_franquia === "" || cnpj_franquia === "" || uf_franquia === "Selecione" || tipo_franquia === "Selecione" || tipo_franquia === "") {
                    alert("Campo nome,  e CNPJ são obrigatórios, uf inválido")
                } else {
                    // EDITAR REGISTRO EXISTENTE........................
                    let id = parseInt(localStorage.getItem("id_franquia"))

                    api.put("/franquias_edit/" + id, params2)
                        .then((resp) => {
                            if (resp.status === 200) {
                                localStorage.setItem('uf_franquia', uf_franquia);
                                alert("Registro editado com sucesso!");
                                setShow(false);
                                props.onClose();
                            } else {
                                alert("Erro ao editar dados");
                            }
                        })
                        .catch((err) => {
                            alert("Erro ao editar dados");
                        });
                }
            }
        }
    }

    function ChangeUf(e) {
        setUf_franquia(e.target.value);
        localStorage.setItem('uf_franquia', e.target.value);
    }


    {/*------------------------------------------------------- Cidades inicio -------------------------------------------------------------*/ }
    /*
        function buscarEstados() {
            axios
                .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
                .then((response) => {
                    setUfs(response.data); //todos os estados
                    // console.log(response.data)
                });
        }
    */

    /*
        function PesquisarCidadeCadastrada() { //pesquias as cidades cadastradas pelo id da franquia para preencher o input
    
            let id_franquia = localStorage.getItem('id_franquia');
    
            api.get("/cidades_list", { params: { id_franquia } })
                .then((resp) => {
                    setCidades_abrang_cad(resp.data);
                })
                .catch((err) => {
                    setCidades_abrang_cad([]);
                    alert("Erro ao carregar dados");
                });
        }
    */

    function verificaCidadeCadastrada() { //verifica se a cidade ja esta cadastrada no banco de dados antes de inserir nova cidade
        let nome_cidade = cidadeIncluir;

        api.get("/cidades_procurar", { params: { nome_cidade } })
            .then((resp) => {
                if (resp.data.length > 0) {
                    MostraMensagemCidadeIncluida(); //mostra mensagem que a cidade já esta incluida no bd
                } else {
                    adicionarCidade();
                    setCidadeIncluir('');                  
                }
            })
            .catch((err) => {
                alert("Erro ao verificar dados");
            });
    }

    function adicionarCidade() { //adiciona no bd

        let status_cidade = 'A';
        const params = {
            id,
            nome_cidade: cidadeIncluir,
            id_franquia: parseInt(localStorage.getItem('id_franquia')),
            uf_cidade: uf_franquia,
            status_cidade
        };
        api.post("/cidades_insert", params)
            .then((resp) => {
                if (resp.status === 201) {
                    MostraMensagemInclusao();
                    listarCidadesFranquias();
                    setCidadeIncluir("");

                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                // setDados([]);
                alert("Erro ao inserir dados");
            });
    }

    function ChangeCidadeExcluir(e) {
        //alert(e.target.value)
        setIdCidadeExcluir(e.target.value.split('-')[0]); //id para a exclusao
        setCidadeExcluir(e.target.value.split('-')[1]); //nome cidade pra mostrar no input
    }

    function excluirCidade() {

        api.delete('/cidades_delete/' + idCidadeExcluir)
            .then((retorno) => {
                listarCidadesFranquias();
                MostraMensagem();
                // pesquisarCidadesDuplicadas(); //atualiza lista de cidades aptas
                setCidadeExcluir("")//limpa o campo cidade escolhida para excluir
                setCidadeIncluir("");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function MostraMensagem() {
        setSucessoExclusao(true)
        function esperaAtualizar() {
            setSucessoExclusao(false)
        }
        setTimeout(esperaAtualizar, 2000); // Executará esperaAtualizar 2 segundos     
    }

    function MostraMensagemInclusao() {
        setSucessoInclusao(true)
        function esperaAtualizar() {
            setSucessoInclusao(false)
        }
        setTimeout(esperaAtualizar, 2000); // Executará esperaAtualizar 2 segundos     
    }
    function MostraMensagemCidadeIncluida() {
        setMsnCidadeIncluida(true)
        function esperaAtualizar() {
            setMsnCidadeIncluida(false)
        }
        setTimeout(esperaAtualizar, 2000); // Executará esperaAtualizar 2 segundos     
    }
    {/*--------------------------------------------------------Cidades Fim ----------------------------------------------------------------*/ }


    {/*--------------------------------------------------------Autocomplete Cidades inicio ----------------------------------------------------------------*/ }
    function Filtrar(e) {
        setTextoDigitado(e.target.value);
        ListarCidadesBusca(e.target.value);
        //console.log(texto)
    }

    function SelecionarCidades(id, nome_cidade) {
        setId(id)
        setCidadeIncluir(nome_cidade); //para preencher o input de busca   
        setTextoDigitado("");
        setDadosCidades([]); //fecha caixa relaçao cidades 
    }

    function Clear() {
        setTextoDigitado("");
        setNome_cidade("");
        setCidadeExcluir("");
        setDadosCidades([]);
        setCidadeIncluir("");
        consultacidadespelaUf();
        //pesquisarCidadesDuplicadas();
    }

    function editFranquiaCidadesAbrang() { //para atualizar os dados nomes cidades abrangencia no bd

        const paramCidades = {
            cidades_abrang: nome_cidade_string
        }

        let id = parseInt(localStorage.getItem("id_franquia"))

        api.put("/franquias_edit_abrang/" + id, paramCidades)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Cidades de Abrangencia editado com sucesso!");
                    setCidades_abrang(nome_cidade_string);
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });
        setTextoDigitado("");
        setNome_cidade("");
        setCidadeExcluir("");
        setDadosCidades([]);
        consultacidadespelaUf();
    }

    {/*--------------------------------------------------------Autocomplete Cidades Fim ----------------------------------------------------------------*/ }
    useEffect(() => {
        listarCidadesFranquias();
        consultacidadespelaUf();

        window.addEventListener('openFranquias', (param) => {

            if (param.detail.operacao === "new") {
                Limpar();
                setUf_franquia("Selecione");
                setBtnAbreModal(false);  //para ativar/desativar buton abre modal           
            } else {
                Limpar();
                setUf_franquia("Selecione");
                setBtnAbreModal(true); //para ativar/desativar buton abre modal
                api.get("/franquias_id/" + param.detail.id_franquia)
                    .then((resp) => {
                        setId_franquia(resp.data.id_franquia);
                        localStorage.setItem('id_franquia_temp', resp.data.id_franquia); //para o relatorio pdf
                        setNome_franquia(resp.data.nome_franquia);
                        setRazao_franquia(resp.data.razao_franquia);
                        localStorage.setItem('razao_franquia_temp', resp.data.razao_franquia); //para o relatorio pdf
                        setEnd_franquia(resp.data.end_franquia);
                        localStorage.setItem('end_franquia_temp', resp.data.end_franquia); //para o cep atual do modal
                        setData_inalg(resp.data.data_inalg);
                        setCnpj_franquia(resp.data.cnpj_franquia);
                        setTel_franquia(resp.data.tel_franquia);
                        setCep_franquia(resp.data.cep_franquia);
                        localStorage.setItem('cep_franquia_temp', resp.data.cep_franquia); //para o cep atual do modal
                        setBairro_franquia(resp.data.bairro_franquia);
                        localStorage.setItem('bairro_franquia_temp', resp.data.bairro_franquia); //para o cep atual do modal
                        setUf_franquia(resp.data.uf_franquia);
                        setUf_pesquisa(resp.data.uf_franquia); //para pesquisa todas as cidades no abrir a tela 
                        localStorage.setItem('uf_franquia_temp', resp.data.uf_franquia); //para o cep atual do modal
                        localStorage.setItem('uf_franquia', resp.data.uf_franquia);
                        setCidade_franquia(resp.data.cidade_franquia);
                        localStorage.setItem('cidade_franquia_temp', resp.data.cidade_franquia); //para o cep atual do modal
                        setComp_franquia(resp.data.comp_franquia);
                        setNum_franquia(resp.data.num_franquia);
                        setObs_franquia(resp.data.obs_franquia);
                        setData_encerramento(resp.data.data_encerramento);
                        setMotivo_franquia(resp.data.motivo_franquia);
                        setNome_franqueados(resp.data.nome_franqueados);
                        setTipo_franquia(resp.data.tipo_franquia);
                        setIe_franquia(resp.data.ie_franquia);
                        setIm_franquia(resp.data.im_franquia);
                        setCidades_abrang(resp.data.cidades_abrang);
                        localStorage.setItem('cidades_abrang_temp', resp.data.cidades_abrang); //para o relatorio pdf
                        setData_contrato(resp.data.data_contrato);
                        setData_aniver_cidade(resp.data.data_aniver_cidade);

                    })
                    .catch((err) => {
                        alert("Erro ao carregar dados");
                        console.log(err);
                    });

            }
            //  consultacidadespelaUf();
            listarCidadesFranquias(); //pesquias as cidades cadastradas pelo id da franquia para preencher o select e o text area

            //pesquisarCidadesDuplicadas();
            document.addEventListener('click', (e) => setDadosCidades([])); // quando clicar fora da tela de resultado fecha a mesma zerando o array
            setShow(true);
            setOperacao(param.detail.operacao);
        });


    }, []);


    useEffect(() => {
        //  pesquisarCidadesDuplicadas();
    }, [cidadesEscolhidas]);


    return <Dock position="right"
        isVisible={show}
        fluid={false}
        size={1200}
        onVisibleChange={(visible) => {
            setShow(visible);
        }}
    >
        <div className="container-fluid fundo_cad" id="conteudo-pdf">
            <h4 className="d-inline "> {operacao === "edit" ? "Editar Franquia " : "Nova Franquia"} </h4>
            <div className="row d-flex ">
                <div className="col-4 mt-4 ">
                    <small className="text-secondary">CNPJ</small>
                    <input type="text" className="form-control " onChange={(e) => setCnpj_franquia(e.target.value)} value={cnpj_franquia} />
                </div>

                <div className="col-4 mt-4">
                    <small className="text-secondary">I E</small>
                    <input type="text" className="form-control " onChange={(e) => setIe_franquia(e.target.value)} value={ie_franquia} />
                </div>

                <div className="col-2 mt-4 ms-5">
                    <small className="text-secondary">Data Inalguração</small>
                    <div className="input-group mb-3 blocoIcones_fran ">
                        <input type="text" className="form-control  " id="birth" value={data_inalg} />
                        <input type="date" className=" form-control  dt_inalg-btn" onChange={ChangeDataInalgFranquia} />
                    </div>
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-4 ">
                    <small className="text-secondary">I M.</small>
                    <input type="text" className="form-control " onChange={(e) => setIm_franquia(e.target.value)} value={im_franquia} />
                </div>

                <div className="col-4 ">
                    <small className="text-secondary">Modalidade</small>
                    <select type="text" className="form-control " onChange={(e) => setTipo_franquia(e.target.value)} value={tipo_franquia} >
                        <option>Selecione</option>
                        <option>Premium</option>
                        <option>Compacta</option>
                        <option>Quiosque</option>
                        <option>Estante Ludica</option>
                    </select>
                </div>

                <div className="col-2 ms-5">
                    <small className="text-secondary ">Tel.</small>
                    <input type="text" className="form-control tel-fran" onChange={(e) => setTel_franquia(e.target.value)} value={tel_franquia} />
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-9">
                    <small className="text-secondary">Razão Social</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setRazao_franquia(e.target.value)} value={razao_franquia} />
                </div>
            </div>

            <div className="row d-flex justify-content-between mt-2">
                <div className="col-9">
                    <small className="text-secondary">Nome Fantasia</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setNome_franquia(e.target.value)} value={nome_franquia} />
                </div>
                <div className="col-2">
                    <div className="col-2">
                        <small className="text-secondary">CEP:</small>
                        <div className="col-11 blocoIconesCep-cad ">
                            <input type="text" className="form-control input-cep-cr  " onChange={(e) => setCep_franquia(e.target.value)} value={cep_franquia} />
                            <button data-bs-toggle="modal" data-bs-target="#ModalCep"><i className=" bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-9">
                    <small className="text-secondary">Rua/Av.</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setEnd_franquia(e.target.value)} value={end_franquia} />
                </div>

                <div className="col-1 ms-5">
                    <div className="col-12">
                        <small className="text-secondary">Número</small>
                        <input type="text" className="form-control " onChange={(e) => setNum_franquia(e.target.value)} value={num_franquia} />
                    </div>
                </div>
                <div className="col-1 ms-4">
                    <div className="col-12">
                        <small className="text-secondary">UF</small>
                        <select type="text" className="form-control " onChange={ChangeUf} value={uf_franquia} >
                            <option value="">Selecione</option>
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row d-flex mt-2 ">
                <div className="col-9 ">
                    <small className="text-secondary">Bairro</small>
                    <input type="text" className="form-control " onChange={(e) => setBairro_franquia(e.target.value)} value={bairro_franquia} />
                </div>
            </div>

            <div className="row d-flex mt-2 ">
                <div className="col-6">
                    <small className="text-secondary">Cidade</small>
                    <input type="text" className="form-control " onChange={(e) => setCidade_franquia(e.target.value)} value={cidade_franquia} />
                </div>
                <div className="col-6">
                    <small className="text-secondary">Complemento</small>
                    <input type="text" className="form-control " onChange={(e) => setComp_franquia(e.target.value)} value={comp_franquia} />
                </div>
            </div>

            <div className="row d-flex ">
                <div className="col-10 ">
                    <small className="text-secondary">Franqueados:</small>
                    <input type="text" className="form-control " onChange={(e) => setNome_franqueados(e.target.value)} value={nome_franqueados} />
                </div>
            </div>
            <div className="row d-flex mt-2 ">
                <div className="col-10">
                    <small className="text-secondary">Obs Franquia:</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setObs_franquia(e.target.value)} value={obs_franquia} />
                </div>
            </div>
            <div className="row d-flex mt-2 ">
                <div className="col-2  ">
                    <small className="text-secondary">Data Contrato</small>
                    <input type="text" className="form-control cpf " onChange={(e) => setData_contrato(e.target.value)} value={data_contrato} />
                </div>

                <div className="col-6 ">
                    <small className="text-secondary  ">Cidades de Abrangência:</small>
                    <div className="inputFran-cad ">
                        <input type="text" className="form-control" value={cidades_abrang} />
                        <button onClick={Clear} disabled={!btnAbreModal} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#ModalEditCidade"><i className=" bi bi-search inputFran-cad"></i></button>
                    </div>
                </div>

                <div className="col-2 ">
                    <small className="text-secondary">Data Aniver. Cidade</small>
                    <div className="input-group mb-3 blocoIcones_fran ">
                        <input type="text" className=" form-control  " onChange={(e) => setData_aniver_cidade(e.target.value)} value={data_aniver_cidade} placeholder="dia/mês" />
                    </div>
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-2">
                    <small className="text-secondary">Data Encerramento</small>
                    <input type="text" className="form-control " onChange={(e) => setData_encerramento(e.target.value)} value={data_encerramento} />
                </div>

                <div className="col-8 ">
                    <small className="text-secondary">Motivo</small>
                    <input type="text" className="form-control " onChange={(e) => setMotivo_franquia(e.target.value)} value={motivo_franquia} />
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center fundo_cad ">
            <div className="row d-flex div-btn-franquias">
                <div className="text-center mb-5">
                    <button onClick={() => generatePDF(recuperarConteudoParaPDF, personalizacao)} className="btn btn-danger btn-fran-imprimir ms-4 "><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                    <button className="btn btn-outline-primary me-3 ms-3 " onClick={() => setShow(false)}>Cancelar</button>
                    {
                        nivel_user === "admin" ?
                            <button className="btn btn-primary " onClick={SalvarDadosFranquia} disabled={!cnpj_franquia || !razao_franquia}>Salvar Dados</button> : null
                    }
                </div>
            </div>
        </div>

        <div className="modal fade" id="ModalCep" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div class="modal-header">
                        <h1 className="modal-title fs-5" id="ModalCepLabel">Pesquisa CEP / Alteração de endereço.</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="border ">
                            <div className="ms-3 p-2">
                                <h5>
                                    Endereço Atual - CEP: {localStorage.getItem('cep_franquia_temp')}
                                </h5>
                                <h6>
                                    End. {localStorage.getItem('end_franquia_temp')} - Bairro : {localStorage.getItem('bairro_franquia_temp')}<br />
                                </h6>
                                <h6>
                                    Cidade: {localStorage.getItem('cidade_franquia_temp')} -  UF : {localStorage.getItem('uf_franquia_temp')}<br />
                                </h6>
                            </div>
                        </div>

                        <div className="border mt-3">
                            <div className="ms-3 p-2">
                                <div className="d-flex">
                                    <div className="col-3">
                                        <h5>
                                            Novo Endereço
                                        </h5>
                                    </div>

                                    <div className="d-flex col-12">
                                        <div className="col-9 d-flex mb-4">
                                            <div className="d-flex col-4">
                                                <small className="mt-2">CEP:{cep_digitado_temp}</small>
                                                <input className="form-control input-cep-digitado ms-2" type="text" onChange={(e) => setCep_digitado(e.target.value)} value={cep_digitado} placeholder="Digite o CEP..." />
                                            </div>

                                            <div className="d-flex ms-4">
                                                <button onClick={() => getCep(cep_digitado)} type="button" className="btn btn-primary" >Pesquisar Cep</button>
                                                <button onClick={() => usarNovoCep()} type="button" className="btn btn-info ms-3">Usar novo Cep</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6>
                                    End. {end_franquiaModal} - Bairro : {bairro_franquiaModal}<br />
                                </h6>

                                <h6>
                                    Cidade: {cidade_franquiaModal} -  UF : {uf_franquiaModal}<br />
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => cancelarModal()} type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                        <button onClick={() => sair()} type="button" className="btn btn-success buton-sair" data-bs-dismiss="modal" aria-label="Close">Sair</button>
                    </div>
                </div>
            </div>
        </div>


        {/*----------------------------------------------- Modal ecluir cidade Inicio -----------------------------------------*/}
        <div className="modal fade" id="ModalEditCidade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="ModalEditCidade">Incluir / Excluir cidades de abrangência</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 bg-white rounded-4" >
                            <div className="container-fluid h-100  sidebar">
                                <div className="container">
                                    <div className="d-flex">
                                        <div className="d-flex col-10 ">
                                            <small className="mt-2 me-2">Franquia: </small>
                                            <input type="text" className="form-control" value={id_franquia + ' - ' + nome_franquia + ' - ' + uf_franquia} />
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <small>Cidades de abrangência atuais: </small>
                                        <div className="col-12 mt-2">
                                            <textarea type="text" className="form-control" value={nome_cidade_string} />
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row  ">
                                    <div className=" mt-2">
                                        <div className="d-flex mt-2">
                                            <div className="col-5 ">
                                                <div className="autocomplete-cid col-12">
                                                    <input type="text" className="form-control " onChange={Filtrar}
                                                        placeholder='Digite a cidade...' value={textoDigitado} />
                                                    <div className="autocomplete-cid-items">
                                                        {
                                                            dadosCidades.map((item) => {
                                                                return <div key={item.id}
                                                                    className="autocomplete-cid-item"
                                                                    onClick={(e) => SelecionarCidades(item.id, item.nome_cidade)}
                                                                >
                                                                    <b>{item.nome_cidade}</b><br />
                                                                    <small className="text-secondary">Cidade : {item.nome_cidade}</small><br />
                                                                    <small className="text-secondary">UF : {item.uf}</small><br />
                                                                    <small className="text-secondary">Código : {item.id}</small><br />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        textoDigitado.length > 0 ?
                                                            <button className="autocomplete-cid-clear" onClick={Clear}>
                                                                <img src={btnClear} className="autocomplete-cid-img" />
                                                            </button>
                                                            : null
                                                    }
                                                    {
                                                        textoDigitado.length <= 0 ?
                                                            <button className="autocomplete-cid-clear" onClick={Clear}>
                                                                <img src={btnAtualiza} className="autocomplete-cid-img" />
                                                            </button>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-5 ms-3">
                                                <input type="text" className="form-control" value={cidadeIncluir} />
                                            </div>
                                            <div className=" ms-2">
                                                <button onClick={verificaCidadeCadastrada} disabled={!cidadeIncluir} className="btn btn-success ">Incluir</button>
                                            </div>
                                        </div>
                                        {
                                            sucessoInclusao ?
                                                <div className="row">
                                                    <ExibeMensagem msn="Cidade incluída com sucesso!" />
                                                </div> : null
                                        }
                                        {
                                            msnCidadeIncluida ?
                                                <div className="row">
                                                    <ExibeMensagem msn="Cidade já incluída no banco de dados, selecione outra opção!" />
                                                </div> : null
                                        }
                                    </div>
                                </div>
                                <div className="row  ">
                                    <div className=" mt-2">
                                        <div className="row d-flex mt-2">
                                            <div className="col-5 ">
                                                <select className="form-control " name="sel-2" id="sel-2" onChange={ChangeCidadeExcluir} >
                                                    <option value="">Selecione a cidade</option>
                                                    {
                                                        cidadesEscolhidas.map((item) => {
                                                            return <option key={item.id_cidades} value={item.id_cidades + '-' + item.nome_cidade}>{item.nome_cidade}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-5 ms-2">
                                                <input type="text" className="form-control" value={cidadeExcluir} />
                                            </div>
                                            <div className="col-1">
                                                <button onClick={excluirCidade} disabled={!cidadeExcluir} className="btn btn-danger">Excluir</button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        sucessoExclusao ?
                                            <div className="row">
                                                <ExibeMensagem msn="Cidade excluida com sucesso!" />
                                            </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={(e) => franquiasCidadesPDF()} className="btn btn-danger btn-sair-modal-cidades" ><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                        <button type="button" onClick={editFranquiaCidadesAbrang} className="btn btn-primary btn-sair-modal-cidades" data-bs-dismiss="modal">Salvar</button>
                        <button type="button" onClick={Clear} className="btn btn-success btn-sair-modal-cidades" data-bs-dismiss="modal">Cancelar/Sair</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal excluir cidade Fim -----------------------------------------*/}
    </Dock>
}

export default FranquiasCad;