import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../franquias-contratos/franquias-contratos.css"
import AutocompleteFranquia from "../../components/autocomplete-franquia/autocomplete-franquia.jsx";

import { Link } from "react-router-dom";

function FranquiasContratosStorage() {

    const [dados, setDados] = useState([]);
    const [url_doc_franquia, setUrl_doc_franquia] = useState("");
    const [id_franquia, setId_franquia] = useState(0);
    const [nome_franquia, setNome_franquia] = useState("");



    function PesquisarImgContrato() {//pesquisa conforme id_franquia armazenado no storage

        let id_franquia = localStorage.getItem('id_franquia');
        api.get("/franquias_doc_url", { params: { id_franquia } })
            .then((resp) => {
                setDados(resp.data);
                // console.log(resp.data)
            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ChangeUrl(e) {
        setUrl_doc_franquia(e.target.value);
    }

    useEffect(() => {
        setId_franquia(localStorage.getItem('id_franquia'));
        setNome_franquia(localStorage.getItem('nome_franquia'));
        PesquisarImgContrato();
    }, []);

    return <>
        <div className="d-flex justify-content-center container-fundo-fran-cont">
            <div className="d-inline justify-content-center  container-body-fran-cont">

                <div className="d-flex justify-content-between mt-2">
                    <div className=" col-6 ms-4">
                        <input type="text" className="form-control" value={id_franquia + " - " + nome_franquia} />
                    </div>

                    <div className="col-2  mb-3">
                        <select className="form-control select-url " name="url" id="url" onChange={ChangeUrl}>
                            <option value="">Selecione o contrato</option>
                            {
                                dados.map((item) => {
                                    return <option key={item.id_doc_franquias} value={item.url_doc_franquia}>{item.descr_doc}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="col-1 ms-3">
                        <Link to="/franquias" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
                    </div>


                </div>

                <div className="d-flex justify-content-center  overflow-auto">
                    <div className="  d-block">
                        <div className=" col-11">
                            <div className="">
                                <iframe className=" col-11 frame-contratos-franquias ms-4 " src={url_doc_franquia}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>
}
export default FranquiasContratosStorage;