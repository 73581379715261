import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo27.png";
import "../navbar/navbar.css"
import { dadosLogin } from "../../zustand/zuztand";

function Navbar(props) {

    const navigate = useNavigate();
    const { usuario, changeIfLogado, nivel_user } = dadosLogin();  //user é o usuario que logou

    function Logout() {
        changeIfLogado("notLogad");
        navigate('/login');
        
    }

    return <div className="d-flex ">
        <nav className="navbar fixed-top navbar-expand-sm navbar-dark bg-white  mt-3 tam-nav">
            <div className="container-fluid">
                <span className="nav-item">
                    <Link to="/dashboard"><img src={logo} className="logo-franquead me-4" /></Link>
                </span>

                <div className="collapse navbar-collapse " id="navbarSupportedContent">

                    <Link to="/menu-franquias" className={props.tela === "franquias" ? "btn-white me-3 nav-item-btn" : "btn-blue me-3 nav-item-btn"} aria-current="page">Franquias</Link>
                    <Link to="/menu-fornecedores" className={props.tela === "fornecedores" ? "btn-white me-3 nav-item-btn" : "btn-blue me-3 nav-item-btn"} aria-current="page">Fornecedores</Link>
                    <Link to="/menu-colaboradores" className={props.tela === "colaboradores" ? "btn-white me-3 nav-item-btn" : "btn-blue me-3 nav-item-btn"} aria-current="page">Colaboradores</Link>
                    <Link to="/menu-aniversariantes" className={props.tela === "aniversarios" ? "btn-white me-3 nav-item-btn" : "btn-blue me-3 nav-item-btn"} aria-current="page"><i className="fas fa-home"></i>Aniversariantes</Link>
                    <Link to="/menu-calendario" className={props.tela === "calendario" ? "btn-white me-3 nav-item-btn" : "btn-blue me-3 nav-item-btn"} aria-current="page">Calendário</Link>

                    <div className=" me-2 ">
                        <div className="col-2 ">
                            <div className="btn-group menu-aux">
                                <button className="btn text-white btn-sm dropdown-toggle fs-3 " type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-list me-2"></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end ">
                                    <li><a className="dropdown-item ul-menu" href="#">Relatórios</a></li>
                                    {
                                         nivel_user === "admin" ?
                                            <Link to="/manutencao" className="text-decoration-none"><li className="dropdown-item ul-menu">Manutenção</li></Link> : null
                                    }                   
                                    <li> <Link to="#" className="text-decoration-none"><li className="dropdown-item ul-menu">Outros</li></Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a onClick={Logout} className="dropdown-item ul-menu" href="#">Sair</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
}

export default Navbar;